import { addDays, differenceInMilliseconds, format, startOfDay } from 'date-fns'

import { setDay } from '../reducers/now'
import store from '../store'

let dayChangeTimeout: NodeJS.Timeout | null = null

firstUseCheck()

if (module.hot) {
  module.hot.accept()
  module.hot.dispose(() => {
    if (dayChangeTimeout !== null) {
      clearInterval(dayChangeTimeout)
    }
  })
}

function dayChangeCheck() {
  const {
    now: { today: storeToday },
  } = store.getState()

  const now = new Date()
  const newToday = format(now, 'yyyy-MM-dd')

  if (newToday !== storeToday) {
    store.dispatch(setDay(newToday))
  }

  const tomorrow = startOfDay(addDays(now, 1))

  const millisecondsBeforeTomorrow = differenceInMilliseconds(tomorrow, now)

  dayChangeTimeout = setTimeout(dayChangeCheck, millisecondsBeforeTomorrow)
}

function firstUseCheck() {
  const unsub = store.subscribe(() => {
    const {
      config: { rehydrated },
    } = store.getState()
    if (!rehydrated) {
      return
    }

    unsub()

    dayChangeCheck()
  })
}
