import { createAction, createReducer } from '@reduxjs/toolkit'

export type VisibleSlots = {
  cancelled: boolean
  // subscription types
  sub_adult_full: boolean
  sub_adult_weekend: boolean
  sub_school_8_11: boolean
  sub_school_12_16: boolean
  // skill levels
  skill_beginner: boolean
  skill_competitor: boolean
}

export const configureVisibleSlots = createAction<VisibleSlots>(
  'arb-website/planning/filter'
)

export default createReducer<VisibleSlots>(
  {
    cancelled: false,
    sub_adult_full: true,
    sub_adult_weekend: true,
    sub_school_8_11: true,
    sub_school_12_16: true,
    skill_beginner: true,
    skill_competitor: true,
  },
  (builder) => {
    builder.addCase(configureVisibleSlots, (state, { payload }) => payload)
  }
)
