import { createAction, createReducer } from '@reduxjs/toolkit'
import { getISOWeek, getISOWeekYear, parseISO } from 'date-fns'

export type Now = {
  today: string
  currentWeek: string
}

export const setDay = createAction<string>('arb-website/now/set-day')

export default createReducer<Now>(
  { today: '2024-08-10', currentWeek: '2024-W32' },
  (builder) => {
    builder.addCase(setDay, (state, { payload }) => {
      const d = parseISO(payload)
      state.today = payload
      state.currentWeek = `${getISOWeekYear(d)}-W${String(
        getISOWeek(d)
      ).padStart(2, '0')}`
    })
  }
)
