import * as React from 'react'

import { useAppSelector } from '../../store'

export default function RehydrationWaiter({
  children,
}: {
  children: React.ReactNode
}) {
  const rehydrated = useAppSelector(({ config: { rehydrated } }) => rehydrated)
  return rehydrated ? children : null
}
