const primaryHue = '153deg'
const secondaryHue = '35deg'

export const COLORS = {
  white: '0deg 0% 100%',
  gray: {
    100: '185deg 5% 95%',
    300: '190deg 5% 80%',
    500: '196deg 4% 60%',
    700: '220deg 5% 40%',
    900: '220deg 3% 20%',
  },
  primaryHue,
  primary: `${primaryHue} 59% 22%`,
  hover: `${primaryHue} 59% 17%`,
  altHover: `${primaryHue} 30% 85%`,
  primaryLight: `${primaryHue} 35% 36%`,
  hoverLight: `${primaryHue} 45% 25%`,
  outline: `${primaryHue} 70% 15%`,
  secondary: `${secondaryHue} 100% 33%`,
  secondaryHover: `${secondaryHue} 30% 85%`,
  secondaryOutline: `${secondaryHue} 100% 26%`,
  background: '60deg 7% 96%',
  backgroundCard: '0deg 0% 100%',
}

export const WEIGHTS = {
  light: 300,
  medium: 400,
  bold: 550,
}

export const BREAKPOINTS = {
  mini: 300,
  phone: 600,
  tablet: 950,
  laptop: 1300,
}

export const QUERIES = {
  miniAndSmaller: `(max-width: ${BREAKPOINTS.mini / 16}rem)`,
  phoneAndSmaller: `(max-width: ${BREAKPOINTS.phone / 16}rem)`,
  tabletAndSmaller: `(max-width: ${BREAKPOINTS.tablet / 16}rem)`,
  laptopAndSmaller: `(max-width: ${BREAKPOINTS.laptop / 16}rem)`,
}
