import { combineReducers } from 'redux'

import config from './config'
import expandedNavTitle from './expandedNavTitle'
import now from './now'
import visibleSlots from './visibleSlots'

const rootReducer = combineReducers({
  config,
  now,
  visibleSlots,
  expandedNavTitle,
})

export type RootState = ReturnType<typeof rootReducer>

export default rootReducer
