import { Provider } from 'react-redux'
import React from 'react'
import type { ReactNode } from 'react'

import GlobalStyles from './src/components/GlobalStyles'
import RehydrationWaiter from './src/components/RehydrationWaiter'
import store from './src/store'

import './src/lib/clock'

export const wrapRootElement = ({ element }: { element: ReactNode }) => {
  return (
    <Provider store={store}>
      <RehydrationWaiter>{element}</RehydrationWaiter>
    </Provider>
  )
}

export const wrapPageElement = ({ element }: { element: ReactNode }) => {
  return (
    <>
      <GlobalStyles />
      {element}
    </>
  )
}
