import { createGlobalStyle } from 'styled-components'

import { COLORS, WEIGHTS } from '../../constants'

export default createGlobalStyle`
/* https://courses.joshwcomeau.com/css-for-js/treasure-trove/010-global-styles */

/*
  1. Use a more-intuitive box-sizing model.
*/
*, *::before, *::after {
  box-sizing: border-box;
}

/*
  2. Remove default margin
*/
* {
  margin: 0;
}

/*
  3. Allow percentage-based heights in the application
*/
html, body {
  height: 100%;
}

/*
  Typographic tweaks!
  4. Add accessible line-height
  5. Improve text rendering
*/
body {
  line-height: 1.5;
  -webkit-font-smoothing: antialiased;
}

/*
  6. Improve media defaults
*/
img, picture, video, canvas, svg {
  display: block;
  max-width: 100%;
}

/*
  7. Remove built-in form typography styles
*/
input, button, textarea, select {
  font: inherit;
}

/*
  8. Avoid text overflows
*/
p, h1, h2, h3, h4, h5, h6 {
  overflow-wrap: break-word;
}

/*
  9. Create a root stacking context
*/
#root, #__next, #___gatsby {
  isolation: isolate;
}

/* CSS Variables */
html {
  --font-weight-bold: ${WEIGHTS.bold};
  --font-weight-medium: ${WEIGHTS.medium};
  --font-weight-light: ${WEIGHTS.light};

  --color-white: hsl(${COLORS.white});
  --color-primary: hsl(${COLORS.primary});
  --color-hover: hsl(${COLORS.hover});
  --color-alt-hover: hsl(${COLORS.altHover});
  --color-primary-light: hsl(${COLORS.primaryLight});
  --color-primary-light-hover: hsl(${COLORS.hoverLight});
  --color-outline: hsl(${COLORS.outline});
  --color-secondary: hsl(${COLORS.secondary});
  --color-secondary-hover: hsl(${COLORS.secondaryHover});
  --color-secondary-outline: hsl(${COLORS.secondaryOutline});
  --color-background: hsl(${COLORS.background});
  --color-background-card: hsl(${COLORS.backgroundCard});
  --color-gray-100: hsl(${COLORS.gray[100]});
  --color-gray-300: hsl(${COLORS.gray[300]});
  --color-gray-500: hsl(${COLORS.gray[500]});
  --color-gray-700: hsl(${COLORS.gray[700]});
  --color-gray-900: hsl(${COLORS.gray[900]});
  --color-red: hsl(358deg 64% 49%);
  --color-green: hsl(153deg 59% 22%);
  --color-blue: hsl(242deg 50% 55%);

  --color-backdrop: hsl(${COLORS.gray[700]} / 0.8);

  --icon-size: ${24 / 16}rem;
  --item-padding: clamp(5px, 0rem + 0.8vw, 8px);
  --side-margin: clamp(var(--icon-size), 7.4vw - 36px, 128px);
  --app-bar-height: clamp(${48 / 16}rem, 4vw + 1.75rem, ${64 / 16}rem);
}

p {
  text-wrap: pretty;
}

h1, h2, h3, h4, h5, h6 {
  text-wrap: balance;
}

/* Default styling */
p.mdx, ol.mdx, ul.mdx {
  margin-bottom: ${16 / 24}rem;
}

a {
  text-decoration: none;
  color: var(--color-secondary);
}

strong {
  font-weight: var(--font-weight-bold);
}

code {
  font-family: monospace;
}

blockquote {
  margin-left: 28px;
  border-left: 4px solid var(--color-gray-500);
  padding-left: 8px;
}

hr {
  border-top: 1px solid var(--color-gray-500);
}

* {
  font-family: "Raleway", sans-serif;
  font-feature-settings: 'lnum';
  font-weight: var(--font-weight-medium);
}

`
