import { configureStore, type StoreEnhancer } from '@reduxjs/toolkit'
import { offline } from '@redux-offline/redux-offline'
import {
  type TypedUseSelectorHook,
  useDispatch,
  useSelector,
} from 'react-redux'

import DEFAULT_STATE from './default-state'
import rootReducer from './reducers'
import type { RootState } from './reducers'

const state = (
  process.env.NODE_ENV === 'production' ? {} : DEFAULT_STATE
) as RootState

const shouldPersist = process.env.NODE_ENV !== 'test'

const store = configureStore({
  devTools: process.env.NODE_ENV !== 'production',
  preloadedState: state,
  reducer: rootReducer,
  enhancers: (getDefaultEnhancers) =>
    shouldPersist ? getDefaultEnhancers().concat(offline({}) as StoreEnhancer) : getDefaultEnhancers(),
})

if (process.env.NODE_ENV !== 'production' && module.hot) {
  module.hot.accept('./reducers', () => store.replaceReducer(rootReducer))
}

type AppDispatch = typeof store.dispatch

export const useAppDispatch: () => AppDispatch = useDispatch
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector

export default store
